.error-page {
    text-align: center;
    margin-top: 60px;

    h1 {
        margin-top: 8px;
    }

    p {
        margin-bottom: 8px;
    }
}