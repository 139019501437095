@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: $inner-xxs;

  .title {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    margin: 0;

    @include from-tablet {
      text-align: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: $inner-sml;
  }

  .description {
    font-size: 0.875rem;
    text-align: left;
    margin: 0;
  }

  .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    column-gap: $inner-sml;

    ion-input {
      flex: 1;
    }
  }
}

.logo {
  height: 100%;
  width: auto;
  object-fit: contain;
  flex-shrink: 0;
  max-height: 56px;
  margin-bottom: $inner-sml;
  max-width: 6rem;

  &.medium {
    max-height: 112px;
    max-width: 16rem;
  }
}
