@import '../../../styles/variables';

.application-page__learning-path__title {
  text-align: center;
  font-size: 28px;
  margin: 0;
}

ion-item.application-page__learning-path__accordion-title {
  text-align: center;
  font-size: 22px;

  ion-icon {
    display: none;
  }
}

// Inner content block
.card-panel.application-page__learning-path__card {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  text-align: center;

  .application-page__learning-path__chip {
    display: inline-block;
    padding: 4px 8px;
    margin: 0 auto $inner-sml;
    font-weight: bold;
    color: #0f5323;
    border: 2px solid $nxu-font-primary;
    border-radius: 4px;
  }

  p {
    text-align: left;
    margin: 0 0 $inner-sml;
  }

  ul {
    text-align: left;
    margin-top: 0;
  }

  ion-button {
    min-height: 33px;
    @include from-desktop-sml {
      position: absolute;
      bottom: 16px;
      width: calc(100% - 32px);
    }
  }
}

// Mobile wrapper
ion-accordion-group.application-page__learning-path__accordion {
  margin: 0 0 $spacing-sml;

  ion-item::part(native) {
    background: $nxu-bg-contrast;
  }

  ion-icon {
    color: $nxu-font-primary;
  }

  ion-icon.application-page__learning-path__accordion-icon {
    margin: $inner-sml $inner-sml $inner-sml 0
  }

  ion-item.application-page__learning-path__accordion-title {
    &::part(native) {
      color: black;
    }
  }

  .card-panel.application-page__learning-path__card {
    margin-bottom: 0;
    border-radius: 0 0 6px;
    border: none;
  }

  .card-panel.application-page__learning-path__card--rec {
    border: 4px solid $nxu-font-primary;
  }
}

// Deskop Wrapper
.application-page__learning-path__flow {
  @include from-desktop-sml {
    display: flex;
    flex-direction: row;
    gap: $spacing-sml;
    margin-bottom: $spacing-sml;
  }

  .card-panel.application-page__learning-path__card {
    margin: 0 0 $spacing-sml;

    @include from-desktop-sml {
      margin: 0;
      padding-bottom: 66px;
      width: 50%;
    }

    h3 {
      margin: $inner-sml 0 $inner-med;
      line-height: 1;

      @include from-desktop-sml {
        margin: $inner-sml 0 53px;
      }
    }
  }

  .card-panel.application-page__learning-path__card--rec {
    border: 4px solid $nxu-font-primary;

    h3 {
      margin: $inner-sml 0;
    }

    ion-button {
      @include from-desktop-sml {
        bottom: 12px;
      }
    }
  }
}

