@import '../../../styles/variables';

.nxu-phone-input__wrapper {
    > div {
        border: 1px solid #d3d3d3;
        border-radius: $nxu-border-radius-sml;
        padding: $inner-xs;

        &:only-child {
            margin-bottom: $inner-sml;
          }
    }

    &.nxu-phone-input__wrapper--error {
        > div {
            border-color: $nxu-danger;
        }

        ion-note {
            margin-left: $inner-med;
            margin-top: 5px;
            margin-bottom: $inner-sml;
        }
    }
}