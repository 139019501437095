@import '../styles/variables';
@import '../styles/overrides/ionic';

:root {
    --ion-background-color: #e3e3e3;
	--ion-background-color-rgb: rgb(227, 227, 227);

    --ion-text-color: #000;
	--ion-text-color-rgb: 0,0,0;

	--ion-overlay-background-color: #efefef;
	--ion-overlay-background-rgb: 239,239,239;

	--ion-item-background: #efefef;
	--ion-item-background-rgb: 239,239,239;

	--ion-item-color: #000;
	--ion-item-color-rgb: 0,0,0;

	--ion-color-primary: #098b5b;
	--ion-color-primary-rgb: 9,139,91;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #087a50;
	--ion-color-primary-tint: #22976b;

	--ion-color-secondary: #3880ff;
	--ion-color-secondary-rgb: 56,128,255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #3171e0;
	--ion-color-secondary-tint: #4c8dff;

	--ion-color-tertiary: #000;
	--ion-color-tertiary-rgb: 0,0,0;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #000000;
	--ion-color-tertiary-tint: #1a1a1a;
}

body  {
    font-family: $nxu-font-main;
	font-weight: $nxu-font-weight;
	line-height: $nxu-line-height;
}