@import '../../../styles/variables';

ion-header.product-selection-modal__header {
  ion-toolbar {
    --background: #ffff54;
    --color: black;
  }
  ion-button {
    margin-right: $inner-sml;
  }
}

ion-content.product-selection-modal__content {
  --background: #fff;

  .product-selection-modal__content-inner {
    padding: $spacing-sml;
  }

  h3 {
    font-size: 18px;
    margin: 0 0 $inner-lrg;
  }

  .product-selection-modal__tab-section {
    border-bottom: 1px solid #aaa;
    margin-bottom: $inner-xs;
  }

  .product-selection-modal__selected {
    min-height: 100px;
    padding-bottom: $inner-sml;
  }

  ion-card.nxu-alert {
    margin-top: 0;
    margin-bottom: $inner-med;
  }
}


