@import '../../../styles/variables';

.application-form.identity-document__form {
  margin: $inner-med auto $spacing-med;

  > img {
    width: 100%;
    max-width: 400px;
    margin: 0 auto $inner-med;
  }

  > ion-card {
    margin-bottom: $inner-med;
  }
}

.identity-document__field-wrapper {

  .identity-document__field-row {
    display: flex;
    align-items: center;
    width: 100%;

    &.identity-document__field-row--comment {
      padding: $inner-sml $spacing-lrg 0;
    }

    button.identity-document__upload-btn {
      position: relative;
      display: flex;
      align-items: center;
      width: calc(100% - 76px);
      height: 54px;
      padding: 0 $spacing-lrg 0 $inner-lrg;
      border: 1px solid $nxu-border-color;
      border-radius: $nxu-border-radius-sml;

      &:hover {
        border-color: $nxu-border-color;
      }

      &:focus {
        border-color: $nxu-font-primary;
      }

      span {
        font-size: 14px;
        min-height: 1rem;
        width: 100%;
      }

      &.identity-document__upload-btn--complete {
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      ion-icon {
        position: absolute;
        right: 0;
        height: 30px;
        width: 30px;
        padding-right: $inner-xs;

        @include from-mobile-med {
          padding: $inner-sml;
        }
      }
    }
  }

  .identity-document__field-row__cta {
    ion-button {
      display: flex;
      align-items: center;
      --padding-end: 0;
      --padding-start: 0;
      margin: 0 4px;
    }

    ion-icon {
      height: 30px;
      width: 30px;
    }
  }

  &.identity-document__field-wrapper--error {
    button.identity-document__upload-btn {
      border-color: $nxu-danger;
      &:hover, &:focus {
        border-color: $nxu-danger;
      }
    }

    ion-note {
      display: inline-flex;
      font-size: 12px;
      margin: 0 38px;
      padding: $inner-xs $inner-xs 0;
    }
  }

}

ion-popover.upload-field-tooltip {
  --background: #efefef;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);

  p {
    padding: $inner-med;
    margin: 0;
  }
}