@import '../../../styles/variables';


.application-page__screening-panel {

  .application-page__screening-panel-title {
    text-align: center;
    font-size: 18px;
    font-weight: $nxu-font-weight;
    margin: 12px 0 24px;
  }

  ion-input {
    margin-bottom: $inner-sml;
    --background: #fff;
    --border-color: #d3d3d3;
  }

  ion-button {
    margin: 18px 0 12px;
  }
}