@import '../../../styles/variables';

.page-content {
    width: calc(100% - 32px);
    max-width: $nxu-page-width;
    min-height: calc(100% - 96px);
    margin: 0 auto;

    @include from-tablet {
        width: calc(100% - 60px);
    }

    &.page-content__full {
        width: 100%;
        max-width: initial;
    }
}

div.nxu-footer {
    width: 100%;
    height: 60px;
    background: #262626;

    div.nxu-footer__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1140px;
        margin: 0 auto;
        padding: $inner-xs;
    }

    svg {
        width: 75px;
    }

    svg > * {
        fill: $nxu-font-light;
    }

    .nxu-footer__title, a {
        color: $nxu-font-light;
    }

    .nxu-footer__title {
        font-size: 0.75rem;
        font-weight: $nxu-font-weight;
        text-align: center;
        padding: 0 $inner-xs;
        max-height: 50px;
        overflow: hidden;
    }

    a {
        font-size: 0.85rem;
    }
}
