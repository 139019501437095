@import '../../../styles/variables';

.alert-card-panel {

    &.alert-card-panel--no-border {
        border: none;
        margin-bottom: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        padding: $inner-sml;
    }

    &__title {
        display: inline-flex;
        align-items: center;
        gap: 6px;

        &--success {
            color: $nxu-font-primary;
        }
    }

    &__title,
    &__message {
        margin: 0;
    }
    
    &__icon {
        flex-shrink: 0;
    }
}
  