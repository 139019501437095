@import '../../../styles/variables';

ion-header.product-details-modal__header {
  ion-toolbar {
    --background: #ffff54;
    --color: black;
  }
  ion-button {
    margin-right: $inner-sml;
  }
}

ion-content.product-details-modal__content {
  --background: #fff;

  .product-details-modal__content-inner {
    padding: $spacing-sml;
  }

  h2 {
    margin-top: $inner-xs;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  h3 {
    font-size: 18px;
    margin: 0 0 $inner-xs;
  }

  div.application-title-panel__row {
    padding: $inner-med 0;
    border-bottom: 1px solid $nxu-border-color;
    &:last-child {
      border: none;
    }

    .application-title-panel__row__title {
      display: flex;
      justify-content: start;
      align-items: center;
      margin: 4px 0 $inner-sml;

      h3 {
        margin: 0 $inner-xs 0 0;
      }
    }

    .application-title-panel__row__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      align-items: center;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;

    }
  }

  div.application-title-panel__stats-row {
    padding-top: 0;

    .application-title-panel__row__content {
      > div {
        display: flex;
        align-items: center;
        margin: 4px 12px;
      }

      ion-icon {
        margin-right: 2px;
      }
    }


    span {
      line-height: 1em;
    }
  }

  .application-title-panel__desc-row {
    p {
      font-size: 14px;
      margin: 0 8px;
    }
  }

  div.application-title-panel__row--tag-cloud {
    .application-title-panel__row__content {
      justify-content: flex-start;

      span {
        font-size: 14px;
        background: $nxu-border-color;
        padding: 4px 6px;
        margin: 4px;
        border-radius: 4px;
      }
    }
  }
}

ion-button#stack-tooltip-trigger {
  width: auto;
  height: auto;
  margin-left: $inner-xs;
  --padding-end: 3px;
  --padding-start: 3px;
  --padding-top: 3px;
  --padding-bottom: 3px;
}

ion-popover.stack-tooltip {
  --background: #efefef;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);

  p {
    padding: $inner-med;
    margin: 0;
  }
}
