@import '../../../styles/variables';

.application-title-panel {
  padding: $inner-sml;

  ion-button {
    margin: 0 0 0 $inner-sml;
    min-height: 40px;
    min-width: 40px;

    &::part(native) {
      height: 40px;
      width: 40px;
      font-size: 15px;
    }
  }

  div.application-title-panel__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  div.application-title-panel__row--title {
    &:not(:last-child) {
      padding-bottom: 6px;
      border-bottom: 1px solid $nxu-border-color;
    }

    > div {
      display: block;
      width: 100%;
      text-align: center;
    }

    h1 {
      margin: 0;
      font-size: 22px;
      line-height: 1em;
      font-weight: bold;
    }
  }

  div.application-title-panel__row--extra {
    padding-top: 6px;

    ion-button {
      font-size: 10px;
    }

    .application-title-panel__row__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      > div {
        display: flex;
        align-items: center;
        margin: 4px 12px;
      }

      ion-icon {
        font-size: 18px;
        margin-right: 3px;
      }
    }

    .application-title-panel__row__spinner {
      display: flex;
      padding-right: 8px;
    }

    span {
      line-height: 1em;
    }
  }
}