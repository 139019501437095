@import '../../../styles/variables';

ion-toolbar.nxu-header__titlebar {
  //--background: #ffff54;
  // Temp style to match ApplyUI, to be ditched when ApplyUI is
  --background: #fff;
  
  padding: 0 $inner-sml;
  @include from-mobile-med {
    padding: 0 $inner-med;
  }

  ion-title {
    display: block;
    // Temp style to match ApplyUI, to be ditched when ApplyUI is
    max-width: 1140px;
    margin: 0 auto;

    padding: $inner-med 0 $inner-med $inner-sml;
    @include from-mobile-med {
      padding: $inner-med 4px;
    }

    a, img, svg {
      display: block;
    }
  }
}