@import '../../../styles/variables';

.complete-page__title {
    text-align: center;
    padding: $inner-sml;
    
    h1 {
        margin: 0;
        font-size: 18px;
    }
}

.complete-page__info-panel {
    > ion-icon {
        font-size: 48px;
        color: $nxu-font-primary;
    }

    > * {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
}