.document-viewer__html {
    height: 450px;
    // Parent component should control the width of the document,
    // iFrame only needs to expand to fill the available space
    width: 100%;
    overflow-y: auto;
    user-select: none;
    background: white;

    html{
        background-color:white ;
    }
    body{
        font-family:arial;
        padding: 10pt in !important;
        background-color: white;
        margin-left: 10px;
        margin-right: 10px;
    }

    p { 
        font-family:arial;
        font-size: 14pt;
        margin-top: 5pt;
        margin-bottom: 10pt;
        text-align: justify;
    }

    h1 { 
        margin-top: 15pt;
        margin-bottom: 10pt;
    }

    h2 { 
        margin-top: 15pt;
        margin-bottom: 5pt;
    }

    h3 { 
        margin-top: 10pt;
        margin-bottom: 5pt;
    }

    li { 
        font-family:arial;
        font-size: 13pt;
    }

    table {
        width:70%;
        border-collapse:collapse;
        font-family:arial;
        font-size: 13pt;
    }

    table thead th { border-bottom: 1px solid #000; }
    th { text-align: left; }
    thead { display: table-row-group; }
    tfoot { display: table-row-group; }
    tr { page-break-inside: avoid; }

    .sig-sig {
        font-weight: 400;
        font-style: italic;
    }

    .sig-date,.sig-title {
        font-weight: 400;
    }

    &.mobile {
        body{
            padding: 10pt;
        }
    }
}