@import '../../../styles/variables';


.applicant-status__info-panel {
  > ion-icon {
      font-size: 48px;
      color: $nxu-font-primary;
  }

  > * {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
  }
}
