@import '../../../../styles/variables';

ion-header.provider-modal__header {
  ion-toolbar {
    --background: #ffff54;
    --color: black;
  }
  ion-button {
    margin-right: $inner-sml;
  }
}

ion-content.provider-modal__content {
    --background: #fff;
}

ion-footer.provider-modal__footer {
  ion-toolbar {
    --background: #efefef;
    text-align: center;
  }
  ion-button {
    margin: $inner-sml 0;
  }
}

.provider-modal__content {
  .provider-modal__content-inner {
    padding: $spacing-sml;
  }

  ul {
    list-style:circle;
    padding-left: $inner-lrg;
    padding-bottom: $inner-med;
    border-bottom: 1px solid;
    margin-bottom: $inner-xl;
  }
  li {
    margin-bottom: $inner-xs;
  }
}