@import '../../../styles/variables';

.catalog-section {
  display: flex;
  flex-direction: column;
  max-width: $nxu-form-width;
  border-top: 1px solid $nxu-border-color;
  margin: $spacing-med auto 0;
  padding-top: $spacing-med;
  text-align: center;

  h2, p {
      margin-top: 0;
      margin-bottom: $inner-med;
  }
}