@import '../../../styles/variables';

.card-panel {
    background: $nxu-bg-contrast;
    border: 1px solid $nxu-border-color;
    border-radius: $nxu-border-radius;
    padding: $inner-sml;
    margin-bottom: $spacing-sml;
    
    @include from-mobile-med {
        padding: $inner-med;
    }

    &.card-panel--centre {
        text-align: center;
    }
}
  