@import '../../../styles/variables';


.price-notification-block {
  .price-notification-block--content {
    display: flex;
    align-items: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  ion-icon {
    font-size: 36px;
    width: 36px;
    min-width: 36px;
    color: $nxu-font-primary;
    margin: 0 $inner-med 0 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

}
