@import '../../../styles/variables';

.nxu-select {
  width: 100%;

  &:only-child {
    margin-bottom: $inner-sml;
  }

  .nxu-select__control {
    height: 50px;
    border-color: #d3d3d3;
  }

  .nxu-select__control:hover {
    border-color: $nxu-font-dark;
  }

  .nxu-select__control--is-focused {
    border-color: $nxu-font-primary !important;
    border-width: 2px;
    box-shadow: none;
  }

  .nxu-select__menu {
    z-index: 1000;
  }
    
    &.nxu-select--error {
      .nxu-select__control {
        border-color: $nxu-danger;
      }

      .nxu-select__control--is-focused {
        border-color: $nxu-danger !important;
      }
    }
  }
  
.nxu-select + .nxu-select__error-note {
    margin-left: $inner-med;
    margin-top: 5px;
    margin-bottom: $inner-sml;
}

.nxu-select__error-note {
    display: block;
    color: $nxu-danger;
    font-size: 0.75em;
}