@import '../../../styles/variables';

.input__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: $inner-xs;

  .input__label {
    font-size: 0.875rem;
    font-weight: 500;
  }
}
