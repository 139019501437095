@import '../../../styles/variables';

.payment-provider__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  @include from-mobile-med {
    flex-direction: row;
  }

  h3 {
    flex-basis: 100%;
    margin: 0;
  }

  .logo {
    font-size: 18px;
    margin-right: 6px;
  }

  .logo > svg {
    height: 22px;
    fill: #28333b
  }

  ion-button.payment-provider__item {
    position: relative;
    overflow: visible;
    --padding-start: 8px;
    --padding-end: 8px;
    --color: #28333b;
    --border-color: #e8ebed;
    --background: #fff;
    --border-width: 2px;

    flex-basis: 100%;
    width: 100%;

    @include from-mobile-med {
      flex-basis: initial;
      width: auto;
    }


    &:hover, &:focus {
      --background: #fff;
      --border-color: #28333b;
    }

    .text {
      text-transform: initial;
    }

    .check-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px;
      margin-left: 6px;

      ion-icon {
        font-size: 18px;
      }
    }

    &.selected { 
      --color: #40b3ff;
      --border-color: #40b3ff;
      --background: rgba(64, 179, 255, .1);

      .logo > svg {
        fill: #40b3ff;
      }
    }
  }
}

.payment-provider__action {
  text-align: center;
}

.payment-provider__wrapper--skip {
  > * {
    text-align: center;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
}