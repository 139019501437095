@import "../../../styles/variables";

.code-verification-form {
  max-width: $nxu-form-width;
  margin-top: 12px;
  row-gap: $inner-xxs;
  display: flex;
  flex-direction: column;

  .code-verification-form__title {
    font-size: 1rem;
    font-weight: bold;
  }

  ion-input {
    margin-bottom: $inner-sml;
    --background: #fff;
    --border-color: #d3d3d3;
  }

  ion-card {
    margin-bottom: $inner-sml !important;
  }
}

.code-verification__complete {
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $inner-med auto 0;
  padding: $inner-xs;
  color: $nxu-font-primary;
  border: 1px solid $nxu-font-primary;
  border-radius: $nxu-border-radius-sml;

  ion-icon {
    height: 20px;
    width: 20px;
    margin-right: $inner-sml;
  }
}
