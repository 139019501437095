@import '../../../../styles/variables';

.cardholder-form {
  h3 {
    margin-top: 0;
  }

  ion-input {
    margin-bottom: $inner-sml;
    --background: #fff;
    --border-color: #d3d3d3;
  }
}