@import '../../../styles/variables';

.email-verification-form {
  max-width: $nxu-form-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .email-verification-form__title {
    &.email-verification-form__title--hidden {
      display: none;
    }
  }

  .email-verification-form__row {
    display: flex;
    align-items: center;
    margin-bottom: $inner-xs;

    ion-button {
      margin: 0 0 0 $inner-sml;
      min-height: 40px;
      min-width: 40px;

      &::part(native) {
        height: 40px;
        width: 40px;
        font-size: 15px;
      }
    }
  }

  ion-input {
    --background: #fff;
    --border-color: #d3d3d3;

    label {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}