@import '../../../styles/variables';

.success-page__info-panel {
    h1 {
        padding-bottom: $inner-lrg;
        margin: $inner-sml auto $inner-xl;
        color: $nxu-font-primary;
        border-bottom: 1px solid $nxu-font-primary;
    }

    > ion-icon {
        display: block;
        font-size: 72px;
        color: $nxu-font-primary;
    }

    ion-button.button-primary {
        margin: $spacing-med auto;
        text-transform: initial;
        width: 100%;
        font-size: 22px;
        --padding-top: 11px;
        --padding-bottom: 11px;
    }

    > * {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
}