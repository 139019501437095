@import '../../../styles/variables';

.checkout-page__enrollment-agreement {

  &.checkout-page__enrollment-agreement--success {
    background: $nxu-font-primary;
    color: white;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 14px;

      ion-icon {
        margin-right: 8px;
      }

      @include from-mobile-med {
        font-size: 18px;
      }
    }
  }

  .agreement__form-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $inner-med;
    padding-bottom: $inner-sml;
    border-bottom: 1px solid $nxu-border-color;

    h2 {
      text-align: center;
      font-size: 20px;
      margin: 0 $inner-xs 0 0;
      padding: 0;
    }
    ion-button#enrollment-tooltip-trigger {
      --padding-end: 1px;
      --padding-start: 1px;
    }
  }

  .agreement__document {
    padding: 0 $inner-xs;
    margin: 0 0 $spacing-sml;
    @include from-mobile-med {
      padding: 0;
    }
  }

  .agreement__form {
    margin-bottom: 0;
    border-top: 1px solid $nxu-border-color;

    .agreement__form-fields {
      div:first-child {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      ion-input {
        max-width: 300px;
        width: 100%;
        margin: $inner-sml 0;
        --background: #fff;

        &:last-child {
          margin-top: 0;
        }

        @include from-mobile-med {
          margin: $inner-sml $inner-sml;
          &:last-child {
            margin-top: $inner-sml;
          }
        }
      }
    }

    .agreement__form-toolbar {
      padding:  0;

      ion-button {
        display: block;
        max-width: 300px;
        margin: 0 auto $inner-xl;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

ion-popover.enrollment-tooltip {
  --background: #efefef;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);

  p {
    padding: $inner-med;
    margin: 0;
  }
}

