@import '../../../styles/variables';

.application-nav-stepper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $inner-xl;

  &::before {
    position: absolute;
    content: "";
    z-index: 1;
    top: calc(50% - 1px);
    left: 0;
    right: 0;
    height: 2px;
    background-color: $nxu-border-color;
  }

  > div {
    z-index: 2;
  }

  .application-nav-stepper__step {
    display: block;
    margin: 0 14px;
    height: 30px;
    width: 30px;
    border-radius: 14px;
    border: 3px solid $nxu-font-dark;
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    color: $nxu-font-dark;
    background: $nxu-font-light;
  }

  .application-nav-stepper__step--disabled {
    color: $nxu-border-color;
    border-color: $nxu-border-color;
    background: $nxu-font-light;
    border-width: 2px;
    line-height: 27px;
  }

  .application-nav-stepper__step--complete {
    color: $nxu-font-dark;
    border-color: $nxu-font-dark;
    background: $nxu-yellow;
  }

  .application-nav-stepper__step--current {
    background: $nxu-yellow;
  }
}