@import '../../../styles/variables';

.application-page {
    //padding: $spacing-med 0;

    h1 {
        text-align: center;
        margin: 36px;
    }
    h2 {
        text-align: center;
        font-size: 22px;
    }

    .application-page__info-panel {
        > ion-icon {
            font-size: 48px;
            color: $nxu-font-primary;
        }

        > * {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .application-page__info-panel-options {
        display: flex;
        flex-direction: column;
        margin: $inner-med auto;
        padding: $inner-lrg $inner-med $inner-med;
        border-top: 1px solid #d3d3d3;
        
        & > * {
            margin: 0 0 $inner-med;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}