@import '../../../styles/variables';

.identity-page__content-panel {
  max-width: $nxu-form-width;
  margin: 0 auto;
  border: 2px solid $nxu-font-primary;
  border-radius: $nxu-border-radius;
  padding: $inner-med;
  text-align: center;

  h2 {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;
    text-align: center;
    li:first-child {
      font-weight: bold;
    }
  }
}