@import '../../../styles/variables';


.identity-document-page {

  ion-card.nxu-alert {
    max-width: $nxu-form-width;
    margin: 0 auto $spacing-med;
  }

  .application-form.application-form--identity {
    margin: $inner-med auto;
  }

  .identity-document__form-submit {
    display: block;
    width: 100%;
    max-width: $nxu-form-width;
    margin: 0 auto $spacing-med;
  }
}
