// Please DO NOT write any app CSS, in this file.
// CSS might be lost on icon update.

$nexford-icon-font-family: "nexford";
$nexford-icon-font-path: "../assets/fonts";
$font-awesome-font-family: 'Font Awesome\ 5 Free';

$nxu-icon-exam: "\e927";
$nxu-icon-edit-note-pencil: "\e926";
$nxu-icon-creditcard: "\e924";
$nxu-icon-logout: "\e925";
$nxu-icon-arrow-left: "\e921";
$nxu-icon-arrow-right: "\e922";
$nxu-icon-arrow-down: "\e923";
$nxu-icon-envelope: "\e920";
$nxu-icon-facebook: "\e900";
$nxu-icon-LinkedIn: "\e901";
$nxu-icon-Twitter: "\e902";
$nxu-icon-Youtube: "\e903";
$nxu-icon-bank-transfer: "\e904";
$nxu-icon-Book_icon: "\e905";
$nxu-icon-Calendar: "\e906";
$nxu-icon-cancel: "\e907";
$nxu-icon-Certificate: "\e908";
$nxu-icon-Clock-icon: "\e909";
$nxu-icon-course: "\e90a";
$nxu-icon-credit-card: "\e90b";
$nxu-icon-Degree: "\e90c";
$nxu-icon-hourglass: "\e90d";
$nxu-icon-laptop: "\e90e";
$nxu-icon-mail: "\e90f";
$nxu-icon-Mobile-pay_Unused: "\e910";
$nxu-icon-mouse: "\e911";
$nxu-icon-Nexford_document_icon-path1: "\e912";
$nxu-icon-Nexford_document_icon-path2: "\e913";
$nxu-icon-Nexford_document_icon-path3: "\e914";
$nxu-icon-Payment_frequency_icon: "\e915";
$nxu-icon-Program-duration-mini-icon: "\e916";
$nxu-icon-resume-button: "\e917";
$nxu-icon-Save--Continue: "\e918";
$nxu-icon-speaker: "\e919";
$nxu-icon-Success: "\e91a";
$nxu-icon-Totalcost-mini-icon: "\e91b";
$nxu-icon-translation: "\e91c";
$nxu-icon-Voucher_icon_payment: "\e91d";
$nxu-icon-wifi: "\e91e";
$nxu-icon-attention: "\e91f";
$nxu-icon-attention: "\e91f";

//Font awsome icons
$fa-icon-trashbin: "\f2ed";
$fa-icon-arrow-down: "\f107";




@font-face {
  font-family: '#{$nexford-icon-font-family}';
  src:
          url('#{$nexford-icon-font-path}/#{$nexford-icon-font-family}.svg?4bxh9x##{$nexford-icon-font-family}') format('svg'),
          url('#{$nexford-icon-font-path}/#{$nexford-icon-font-family}.woff?4bxh9x') format('woff'),
          url('#{$nexford-icon-font-path}/#{$nexford-icon-font-family}.ttf?4bxh9x') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.nxu-icon, [class^="nxu-icon-"], [class*=" nxu-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$nexford-icon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size:1.6rem;
  padding-right:5px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(-0.0000000001deg);
  -webkit-transform: rotate(-0.0000000001deg);
  -webkit-text-stroke: 0.5px;
}

.fa-icon, [class^="fa-icon-"], [class*=" fa-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$font-awesome-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: 900;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.7rem;
  padding-right: 0.5rem;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(-0.0000000001deg);
  -webkit-transform: rotate(-0.0000000001deg);
  -webkit-text-stroke: 0.5px;
}

.nxu-icon-exam {
  &:before {
    content: $nxu-icon-exam;
  }
}
.nxu-icon-course {
  &:before {
    content: $nxu-icon-course;
  }
}
.nxu-icon-edit-note-pencil {
  &:before {
    content: $nxu-icon-edit-note-pencil;
  }
}
.nxu-icon-creditcard {
  position: relative;

  &:before {
    content: $nxu-icon-creditcard;
  }

  .asterisk {
    &:before {
      content: '*';
      font-family: Roboto, sans-serif;
      font-size: 15px;
      font-weight: 300;
      position: absolute;
      top: 0;
    }
  }
}
.nxu-icon-logout {
  &:before {
    content: $nxu-icon-logout;
  }
}
.nxu-icon-arrow-left {
  &:before {
    content: $nxu-icon-arrow-left;
  }
}
.nxu-icon-arrow-right {
  &:before {
    content: $nxu-icon-arrow-right;
  }
}
.nxu-icon-arrow-down {
  &:before {
    content: $nxu-icon-arrow-down;
  }
}
.nxu-icon-envelope {
  &:before {
    content: $nxu-icon-envelope;
  }
}
.nxu-icon-facebook {
  &:before {
    content: $nxu-icon-facebook;
  }
}
.nxu-icon-LinkedIn {
  &:before {
    content: $nxu-icon-LinkedIn;
  }
}
.nxu-icon-Twitter {
  &:before {
    content: $nxu-icon-Twitter;
  }
}
.nxu-icon-Youtube {
  &:before {
    content: $nxu-icon-Youtube;
  }
}
.nxu-icon-bank-transfer {
  &:before {
    content: $nxu-icon-bank-transfer;
  }
}
.nxu-icon-Book_icon {
  &:before {
    content: $nxu-icon-Book_icon;
  }
}
.nxu-icon-Calendar {
  &:before {
    content: $nxu-icon-Calendar;
  }
}
.nxu-icon-cancel {
  &:before {
    content: $nxu-icon-cancel;
  }
}
.nxu-icon-Certificate {
  &:before {
    content: $nxu-icon-Certificate;
  }
}
.nxu-icon-Clock-icon {
  &:before {
    content: $nxu-icon-Clock-icon;
  }
}
.nxu-icon-course {
  &:before {
    content: $nxu-icon-course;
  }
}
.nxu-icon-credit-card {
  &:before {
    content: $nxu-icon-credit-card;
  }
}
.nxu-icon-Degree {
  &:before {
    content: $nxu-icon-Degree;
  }
}
.nxu-icon-hourglass {
  &:before {
    content: $nxu-icon-hourglass;
  }
}
.nxu-icon-laptop {
  &:before {
    content: $nxu-icon-laptop;
  }
}
.nxu-icon-mail {
  &:before {
    content: $nxu-icon-mail;
  }
}
.nxu-icon-Mobile-pay_Unused {
  &:before {
    content: $nxu-icon-Mobile-pay_Unused;
  }
}
.nxu-icon-mouse {
  &:before {
    content: $nxu-icon-mouse;
  }
}
.nxu-icon-Nexford_document_icon .path1 {
  &:before {
    content: $nxu-icon-Nexford_document_icon-path1;
    color: rgb(0, 0, 0);
  }
}
.nxu-icon-Nexford_document_icon .path2 {
  &:before {
    content: $nxu-icon-Nexford_document_icon-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nxu-icon-Nexford_document_icon .path3 {
  &:before {
    content: $nxu-icon-Nexford_document_icon-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.nxu-icon-Payment_frequency_icon {
  &:before {
    content: $nxu-icon-Payment_frequency_icon;
  }
}
.nxu-icon-Program-duration-mini-icon {
  &:before {
    content: $nxu-icon-Program-duration-mini-icon;
  }
}
.nxu-icon-resume-button {
  &:before {
    content: $nxu-icon-resume-button;
  }
}
.nxu-icon-Save--Continue {
  &:before {
    content: $nxu-icon-Save--Continue;
  }
}
.nxu-icon-speaker {
  &:before {
    content: $nxu-icon-speaker;
  }
}
.nxu-icon-Success {
  &:before {
    content: $nxu-icon-Success;
  }
}
.nxu-icon-Totalcost-mini-icon {
  &:before {
    content: $nxu-icon-Totalcost-mini-icon;
  }
}
.nxu-icon-translation {
  &:before {
    content: $nxu-icon-translation;
  }
}
.nxu-icon-Voucher_icon_payment {
  &:before {
    content: $nxu-icon-Voucher_icon_payment;
  }
}
.nxu-icon-wifi {
  &:before {
    content: $nxu-icon-wifi;
  }
}
.nxu-icon-attention {
  &:before {
    content: $nxu-icon-attention;
  }
}

// Font Awsome icons
.fa-icon-trashbin {
  &:before {
    content: $fa-icon-trashbin;
  }
}

.fa-icon-arrow-down {
  &:before {
    content: $fa-icon-arrow-down;
  }
}
