@import '../../../styles/variables';

.application-form {
  max-width: $nxu-form-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 0;
  }

  ion-input {
    margin-bottom: $inner-sml;
    --background: #fff;
    --border-color: #d3d3d3;
  }

  .application-form__select {
    width: 100%;
    
    &:only-child {
      margin-bottom: $inner-sml;
    }

    .nxu-select__control {
      height: 50px;
      border-color: #d3d3d3;
    }

    .nxu-select__control:hover {
      border-color: $nxu-font-dark;
    }

    .nxu-select__control--is-focused {
      border-color: $nxu-font-primary !important;
      border-width: 2px;
      box-shadow: none;
    }
    
    &.application-form__select--error {
      .nxu-select__control {
        border-color: $nxu-danger;
      }

      .nxu-select__control--is-focused {
        border-color: $nxu-danger !important;
      }
    }
  }

  .application-form__select + ion-note {
    margin-left: $inner-med;
    margin-top: 5px;
    margin-bottom: $inner-sml;
  }

  .application-form__radio-group {
    border: 1px solid #d3d3d3;
    border-radius: $nxu-border-radius-sml;
    padding: $inner-xs;
    margin-bottom: $inner-sml;

    &.application-form__radio-group--error {
      border-color: $nxu-danger;
    }

    p {
      margin: 0 0 $inner-xs;
    }

    label {
      display: inline-block;
      margin-right: $inner-lrg;

      input {
        margin-right: 4px
      };
    }
  }

  .application-form__checkbox {
    > div {
      border: 1px solid #d3d3d3;
      border-radius: $nxu-border-radius-sml;
      padding: $inner-xs;

      &:only-child {
        margin-bottom: $inner-sml;
      }
    }

    label {
      margin-left: $inner-xs;
    }

    button {
      color: $nxu-font-primary;
      background: transparent;
      font-size: inherit;
    }

    &.application-form__checkbox--error {
      > div { 
        border-color: $nxu-danger;
      }
    }

    ion-note {
      margin-left: $inner-med;
      margin-top: 5px;
      margin-bottom: $inner-sml;
    }
  }

  .application-form__error-note {
    display: block;
    color: $nxu-danger;
    font-size: 0.75em;
  }

  .application-form__anglish-assement-note {
    border: 1px solid $nxu-warn;
    border-radius: $nxu-border-radius-sml;
    margin-bottom: $inner-sml;

    ion-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    ion-icon {
      margin-bottom: $inner-xs;
      font-size: 24px;
      color: $nxu-warn;
    }

    ion-text {
      text-align: center;
    }
  }
}

.application-form__submission-error {
  margin-bottom: $inner-sml;
}

.application-form__redirect-pending {
  text-align: center;
  margin: $inner-sml 0 0;
  padding: 0 $inner-sml;
}